import { Link } from "gatsby"
import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import SeoHead from "../components/global/seoHead"
import Layout from "../components/global/layout"
import Seo from "../components/global/seo"
import ScrollIndicationHeightZero from "../components/global/scrollIndicationHeightZero"

import PageNotFoundFeaturedImage from "../../static/home/wowmakers.jpeg"


import '../sass/pages/page-not-found.scss'

export const Head = () => (
  <>
    <SeoHead
      ogImage={PageNotFoundFeaturedImage}
      title="Page not found | WowMakers"
      description="We're a 12-year old UI/UX design company specialized in user experience design (UX), user interface design (UI) & mobile and web engineering."
    />
  </> 
)

const NotFoundPage = () => (
  <Layout>
    <Seo
      bclass="page-error"
      bid="page-error"
    />
    <div className="main align-items-start">
      <ScrollIndicationHeightZero />
      <Container>
        <Row>
          <Col md={12} className="text-center mt-3 pt-5">
            <h1>Uh oh. That page is haunted!</h1>
            <p>Shit happens. Don't worry</p>
            <p>Go back to <Link to="/" className="text-danger">Home</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
